<template>
  <fw-button
    v-if="userPermissions.isAnyManager"
    type="link"
    :disabled="loadingExportToCSV"
    @click.native="exportResults"
  >
    <template #icon>
      <div class="w-5 h-5">
        <fw-icon-loading v-if="loadingExportToCSV" class="flex-shrink-0 w-4 h-4" />
        <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
      </div>
    </template>
    {{ $t('exportCSV') }}
  </fw-button>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {},

  props: {
    editionKey: {
      type: String,
      required: true,
    },
    leagueKey: {
      type: String,
    },
  },

  data() {
    return {
      loadingExportToCSV: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },
  },

  methods: {
    async exportResults() {
      this.loadingExportToCSV = true

      await utils.tryAndCatch(this, async () => {
        const data = await this.api.managementExportTeamsStatistics(this.editionKey, this.leagueKey)
        const url = window.URL.createObjectURL(new Blob([data]))
        utils.downloadFile(url, `Estatísticas_${new Date().toDateString()}.csv`)
      })

      this.loadingExportToCSV = false
    },
  },
}
</script>

<i18n lang="json">
  {
    "pt": {
      "exportCSV": "Exportar para CSV"
    },
    "en": {
      "exportCSV": "Export to CSV"
    }
  }
</i18n>
