<template>
  <section>
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :show-time-period="false"
      :time-period-label="$t('startDate')"
      :can-close="activeModal === null"
      :show-filters="showFilterLabels"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterLabel(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="tournaments ? tournaments.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white"
    >
      <fw-panel-info v-if="!loading && !tournaments.length" type="basic">
        {{ $t('notournaments') }}
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else>
        <div v-for="item in tournaments" :key="item.key" class="p-1 border-b border-gray-100">
          <RecordTournament
            :tournament="item"
            :is-academic="leagues[item.league_key]?.type == 'academic'"
            :sport="sports[item.sport_key] ?? {}"
            @open="goToTournament(item.key)"
          ></RecordTournament>
        </div>
      </div>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </section>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordTournament from '@/components/records/RecordTournament'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import capitalize from 'lodash/capitalize'

export default {
  name: 'PanelManageTournaments',
  components: {
    ContextualSearch,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordTournament,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    maxNumberSpaces: {
      type: Number,
      default: null,
    },
    editionKey: {
      type: String,
      default: null,
    },
    leagueKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filterByType: 'all',
      filters: [
        {
          key: 'is_visible',
          label: this.$t('tournamentstatus.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: true,
              label: this.$t('tournamentstatus.published'),
            },
            {
              key: false,
              label: this.$t('tournamentstatus.draft'),
            },
          ],
        },
        {
          key: 'is_signup_open',
          label: this.$t('tournamentstatus.signups'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: true,
              label: this.$t('tournamentstatus.signup-open'),
            },
            {
              key: false,
              label: this.$t('tournamentstatus.signup-closed'),
            },
          ],
        },
      ],
      orderBy: [
        {
          key: 'title',
          label: this.$t('orderBy.title'),
          type: 'string',
        },
        {
          key: 'created_at',
          label: this.$t('orderBy.startDate'),
          type: 'string',
        },
      ],
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      tournaments: [],
      users: {},
      loading: true,
      activeModal: null,
      filterLabels: {},
      showFilterLabels: false,
      leagues: {},
      sports: {},
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    },
    language() {
      return this.$store.state.language || 'pt'
    },
  },

  watch: {
    filterLabels() {
      //Hackish way to force rerender of filter labels, improve later
      this.rerenderFilterLabels++
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getTournaments()
    })
  },

  methods: {
    getFilterLabel(filter) {
      const [key, label] = filter.split(':')

      if (key == 'sport_keys') {
        const sport = this.filterLabels['sport_keys']?.[label]
        if (sport && sport.category?.[this.language]) {
          return sport.modality?.title?.[this.language] + ' - ' + sport.category?.[this.language]
        }
        return sport.modality?.title?.[this.language]
      }
      return utils.getFilterText(filter, this.filters)
    },
    refresh() {
      //reset and reload tournaments
      this.page = 1
      this.filterByType = 'all'
      this.searchInput = ''
      this.orderByValue = 'created_at'
      this.orderDirection = 'DESC'
      this.appliedFilters = []
      this.startPeriod = null
      this.endPeriod = null
      this.getTournaments()
    },

    goToTournament(tournamentKey) {
      this.$router.push({
        name: 'manage-tournament',
        params: {
          editionKey: this.editionKey,
          leagueKey: this.leagueKey,
          tournamentKey: tournamentKey,
          view: 'dashboard',
        },
      })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getTournaments()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getTournaments()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log('search', data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getTournaments()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getTournaments()
    },

    async getTournaments() {
      this.loading = true
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = { limit: maxNumber, page: this.page, ...utils.setFiltersQuery(this.appliedFilters) }

      // Filter by edition and league
      query['edition_keys'] = [this.editionKey]
      query['league_keys'] = [this.leagueKey]

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      try {
        console.log('gettournaments query :>> ', query)
        const result = await this.api.managementGetTournaments(query)
        console.log('gettournaments :>> ', result)
        this.totalResults = result.tournaments.pagination?.total_items
        this.totalPages = result.tournaments.pagination?.total_pages
        this.page = result.tournaments.pagination?.current_page
        this.tournaments = result.tournaments.tournaments
        this.leagues = result.leagues
        this.sports = result.sports

        this.filterLabels = {}
        this.filterLabels['sport_keys'] = result.sports ?? {}

        //Hidrate filters
        let filters = []
        if (Array.isArray(result.filters.sports)) {
          filters.push({
            key: 'sport_keys',
            label: this.$t('sports'),
            options: result.filters.sports.map(sportKey => {
              const categoryName = result.sports[sportKey]?.category?.[this.language]
              return {
                key: sportKey,
                label: `${result.sports[sportKey]?.modality?.title?.[this.language]} ${
                  categoryName ? ' - ' + capitalize(categoryName) : ''
                }`,
              }
            }),
          })
        }

        this.filters = this.filters.filter(el => el.key != 'sport_keys').concat(filters)
        //Show lables only after we get them from the backend
        this.showFilterLabels = true
      } catch (error) {
        console.error('gettournaments Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getTournaments()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "startDate": "Data de início",
    "results": "Resultados",
    "loadMoreData": "Ver mais resultados",
    "notournaments": "Sem torneios a mostrar.",
    "spaceType": {
      "label": "Tipo de espaço",
      "development": "Desenvolvimento",
      "support": "Suporte"
    },
    "all": "Todos",
    "orderBy": {
      "shortName": "Nome do espaço",
      "spaceStatus": "Estado do espaço",
      "startDate": "Data de início",
      "spaceType": "Tipo de espaço",
      "title": "Titulo do torneio",
      "prefix": "Prefixo do espaço",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    },
    "tournamentstatus": {
      "label": "Estado",
      "draft": "Rascunho",
      "published": "Publicado",
      "signups": "Inscrições",
      "signup-open": "Inscrições abertas",
      "signup-closed": "Inscrições fechadas"
    },
    "callType": {
      "label": "Tipo de procedimento",
      "scholarship": "Apoio",
      "internship": "Estágio",
      "job": "Emprego",
      "other": "Outro"
    },
    "status": "Estado",
    "leagues": "Liga",
    "sports": "Modalidade",
    "orgUnit": "Unidade orgânica",
    "edition": "Edição"
  },
  "en": {
    "startDate": "Start date",
    "results": "Results",
    "notournaments": "No tournaments to present.",
    "loadMoreData": "Load more data",
    "spaceType": {
      "label": "Space type",
      "development": "Development",
      "support": "Support",
      "other": "Other"
    },
    "all": "All",
    "orderBy": {
      "shortName": "Space short name",
      "spaceStatus": "Space status",
      "startDate": "Start date",
      "spaceType": "Space type",
      "title": "Title",
      "prefix": "Space prefix",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "tournamentstatus": {
      "label": "Status",
      "draft": "Draft",
      "published": "Published",
      "signups": "Signups",
      "signup-open": "Signups open",
      "signup-closed": "Signups closed"
    },
    "callType": {
      "label": "call type",
      "scholarship": "Scholarship",
      "internship": "Internship",
      "job": "Job",
      "other": "Other"
    },
    "leagues": "League",
    "sports": "Sport",
    "orgUnit": "Organic unit",
    "edition": "Edition"
  }
}
</i18n>
