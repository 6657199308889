<template>
  <fw-panel :title="$t('tournaments')" featured>
    <template v-if="canCreateTornament" #toolbar>
      <span class="flex flex-1 justify-end">
        <fw-button type="transparent-primary" @click.native="openModalCreateTournament">{{
          $t('newTournament')
        }}</fw-button></span
      >
    </template>

    <PanelManageTournaments
      ref="paneltournaments"
      :edition-key="edition.key"
      :league-key="league.key"
      :show-filters="false"
      @searching="hideStatsOnSearching = true"
    />

    <fw-modal
      v-if="isModalActive"
      size="min"
      width="54rem"
      :active.sync="isModalActive"
      :can-cancel="true"
      inner-scroll
      custom-class="!max-h-[90vh]"
      @close="closeModal"
    >
      <ModalCreateTournaments
        class="overflow-y-auto"
        :league="league"
        :edition-key="edition.key"
        @create-tournament="createTournament($event)"
        @close="closeModal"
      ></ModalCreateTournaments>
    </fw-modal>
  </fw-panel>
</template>

<script>
import PanelManageTournaments from '@/components/panels/manage/PanelManageTournaments'
import ModalCreateTournaments from '@/components/modals/ModalCreateTournaments'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { GROUPED_LEAGUES } from '@/utils/index.js'

export default {
  name: 'ManageTournaments',
  components: {
    PanelManageTournaments,
    ModalCreateTournaments,
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },
    league: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isModalActive: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    },

    canCreateTornament() {
      if (this.league?.type === 'group') return false
      if (this.edition?.group_league_active)
        return !GROUPED_LEAGUES.includes(this.league.type) && this.userPermissions.isGlobalManager

      return this.userPermissions.isGlobalManager
    },
  },

  methods: {
    closeModal() {
      this.isModalActive = false
    },

    openModalCreateTournament() {
      this.isModalActive = true
    },

    async createTournament(payload) {
      utils.tryAndCatch(this, async () => {
        console.log('creating tournament', payload)
        await this.api.managementCreateTournaments(this.league.key, this.edition.key, [payload])
        this.loading = false
        this.$buefy.snackbar.open({
          message: 'Torneio criado com sucesso!',
          type: 'is-success',
        })
        this.closeModal()
        this.$refs.paneltournaments.refresh()
      })
    },
  },
}
</script>

<i18n lang="json">
{
  "pt": {
    "tournaments": "Torneios",
    "newTournament": "Criar torneios",
    "manageSportsAndEditions": "Gerir modalidades e edições",
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar o torneio. Por favor, contacte a nossa equipa de suporte."
    }
  },
  "en": {
    "tournaments": "Tournaments",
    "newTournament": "Create tournaments",
    "manageSportsAndEditions": "Manage sports and editions",
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the tournament. Please contact our support team."
    }
  }
}
</i18n>
