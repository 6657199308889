<template>
  <fw-panel :title="$t('teams')" featured>
    <template #toolbar>
      <b-dropdown aria-role="list" position="is-bottom-left" class="paddingless">
        <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="$t('export')" />
        <b-dropdown-item class="w-56" custom aria-role="menuitem">
          <ButtonExportStatsCSV :edition-key="edition.key" :league-key="league.key"></ButtonExportStatsCSV>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <PanelManageTeams :edition-key="edition.key" :league-key="league.key" :is-academic="league?.type == 'academic'" />
  </fw-panel>
</template>

<script>
import PanelManageTeams from '@/components/panels/manage/PanelManageTeams'
import ButtonExportStatsCSV from '@/components/buttons/ButtonExportStatsCSV'

export default {
  components: {
    PanelManageTeams,
    ButtonExportStatsCSV,
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },
    league: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "teams": "Equipas",
    "export": "Exportar estatísticas"
  },
  "en": {
    "teams": "Teams",
    "export": "Export statistics"
  }
}
</i18n>
