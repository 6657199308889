<template>
  <div class="h-full">
    <fw-panel-info v-if="!loading && !chats.length" type="basic" class="text-center text-gray-500"
      >Sem canais</fw-panel-info
    >
    <PanelChats
      v-if="!loading"
      ref="chat"
      :chats="chats"
      :chat-users="chatUsers"
      :all-chat-users="allChatUsers"
      :chat-active="chatActive"
      :add-allowed="false"
      :view-chats-list="viewChatsList"
      :load-chat-on-create="loadChatOnCreate"
      @load-chat="loadChatAndSetNav"
      @load-main-chat="loadMainChat"
      @unload-chat="unloadChatAndSetNav"
      @set-chat="setChat"
      @set-chat-messages="setChatMessages"
      @delete-chat-message="deleteChatMessage"
    />
  </div>
</template>
<script>
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
// import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'

export default {
  components: {
    PanelChats,
  },

  mixins: [ChatLive],

  props: {
    editionKey: {
      type: String,
      required: true,
    },
  },

  data() {
    this.loadChats()
    const data = this.getChatDefaultData()
    data.loading = true
    data.loadChatOnCreate = true
    data.chatFilters = []
    data.destroying = false
    data.viewChatsList = true
    return data
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
  },

  beforeDestroy() {
    this.destroying = true
    this.unregisterChat()
  },

  created() {
    this.registerChat()
  },

  methods: {
    backTo() {
      if (this.viewChatsList && !this.isMobile) this.$router.back()
      else this.viewChatsList = true
    },
    loadChatAndSetNav($data) {
      this.viewChatsList = false
      this.loadChat($data)
    },
    unloadChatAndSetNav($data) {
      this.viewChatsList = true
      this.unloadChat($data)
    },

    async loadChats() {
      let hash = this.$route.hash
      hash = hash && hash.length > 2 ? hash.substr(1) : null

      const response = await this.$store.state.api.base.getChannels(this.editionKey)
      this.chatFilters = response.types
      for (let channel of response.channels) {
        let loadAfter = channel.key == hash
        this.setChat(channel, loadAfter)
        if (loadAfter) this.loadChatOnCreate = false
      }
      this.setAllChatUnread()
      this.loading = false
    },

    isInChatView() {
      return !this.destroying
    },
  },
}
</script>
