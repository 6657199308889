<template>
  <fw-panel :title="'Criar torneios'" :loading="loading" boxed="xs">
    <div>
      <fw-label>{{ $t('title.label') }}</fw-label>
      <TranslatedInput
        v-model="tournament.title"
        :placeholder="{ pt: $t('title.pt'), en: $t('title.en') }"
        :disable-autowrite="true"
        :multiline="false"
        :class="{
          error: $v.tournament.title.$error,
        }"
      />
      <fw-tip v-if="$v.tournament.title.$error" error>
        {{ $t('title.required') }}
      </fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>{{ $t('description.label') }}</fw-label>
      <TranslatedInput
        v-model="tournament.description"
        :placeholder="{ pt: $t('description.pt'), en: $t('description.en') }"
        :disable-autowrite="true"
        :multiline="true"
      />
      <fw-tip v-if="$v.tournament.description.$error" error>
        {{ $t('description.required') }}
      </fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>Adicionar modalidade</fw-label>
      <div class="flex flex-col gap-3">
        <b-select v-model="tournament.sport_key" placeholder="Seleccione uma modalidade" expanded>
          <option v-for="sport in sports" :key="sport.key" :value="sport.key">
            {{ `${sport.modality.title[language]} ${sport.category[language] ?? ''}` }}
          </option>
        </b-select>
      </div>
      <fw-tip v-if="$v.tournament.sport_key.$error" error> Insira a modalidades dos novos torneios</fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>Género</fw-label>
      <b-field>
        <b-checkbox-button
          v-model="tournament.genders"
          native-value="M"
          class="bg-gray-100 !rounded-r-none rounded-l-xl text-xs"
          size="is-small"
        >
          <span>{{ gendersList['M'][language] }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="tournament.genders"
          native-value="F"
          class="bg-gray-100 !rounded-none text-xs"
          size="is-small"
        >
          <span>{{ gendersList['F'][language] }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="tournament.genders"
          native-value="U"
          class="bg-gray-100 !rounded-l-none rounded-r-xl text-xs"
          size="is-small"
        >
          {{ gendersList['U'][language] }}
        </b-checkbox-button>
      </b-field>
    </div>

    <div v-for="(stage, index) in tournament.stages" :key="index" class="mt-5">
      <fw-heading
        size="md"
        :class="{
          'mb-2': index == 0,
          'mb-2 mt-5': index != 0,
        }"
        >{{ stages[stage.stage]?.[language] }}</fw-heading
      >

      <div>
        <fw-label>Tipo</fw-label>
        <b-select v-model="stage.type" placeholder="Tipo" expanded>
          <option v-for="(option, key) in typesList" :key="key" :value="key">
            {{ option[language] }}
          </option>
        </b-select>
        <fw-tip v-if="$v.tournament.stages.$each[index].type.$error" error> Insira a edição dos novos torneios</fw-tip>
      </div>

      <div v-if="stage.type == 'swiss'" class="mt-4">
        <div class="mb-2">
          <fw-label>Rondas</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.swiss_options.rounds"
              :placeholder="'Pontos por jogo/set ganho'"
              :max="999"
              :min="1"
            ></NumberInput>
          </div>
          <fw-tip v-if="$v.tournament.stages.$each[index].swiss_options.rounds.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].swiss_options.rounds.min">
              Insira um número de rondas superior a 1
            </span>
            <span v-else-if="!$v.tournament.stages.$each[index].swiss_options.rounds.max">
              Insira um número de rondas inferior a 999
            </span>
            <span v-else>Insira o número de rondas</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por jogo/set ganho</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.swiss_options.pts_for_game_win"
              :placeholder="'Pontos por jogo/set ganho'"
              :max="999"
            ></NumberInput>
          </div>
          <fw-tip v-if="$v.tournament.stages.$each[index].swiss_options.pts_for_game_win.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].swiss_options.pts_for_game_win.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de Pontos por jogo/set ganho</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por jogo/set empate</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.swiss_options.pts_for_game_tie"
              :placeholder="'Pontos por jogo/set empate'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tournament.stages.$each[index].swiss_options.pts_for_game_tie.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].swiss_options.pts_for_game_tie.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de Pontos por jogo/set empate</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por partida ganha</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.swiss_options.pts_for_match_win"
              :placeholder="'Pontos por partida ganha'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tournament.stages.$each[index].swiss_options.pts_for_match_win.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].swiss_options.pts_for_match_win.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de pontos por partida ganha</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por partida empate</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.swiss_options.pts_for_match_tie"
              :placeholder="'Pontos por partida empate'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tournament.stages.$each[index].swiss_options.pts_for_match_tie.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].swiss_options.pts_for_match_tie.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de pontos por partida por empate</span>
          </fw-tip>
        </div>
      </div>

      <div v-if="stage.type == 'round_robin'" class="mt-4">
        <div class="mb-2">
          <fw-label>Classificar por</fw-label>
          <b-select v-model="stage.round_robin_options.ranking" expanded>
            <option v-for="ranking in roundRobinRankingOptions" :key="ranking.value" :value="ranking.value">{{
              ranking.label
            }}</option>
          </b-select>

          <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.ranking.$error" error>
            Escolha uma opção
          </fw-tip>
        </div>

        <div class="mb-2">
          <fw-label>Rondas</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="stage.round_robin_options.iterations"
              :placeholder="'Pontos por jogo/set ganho'"
              :max="999"
              :min="1"
            ></NumberInput>
          </div>
          <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.iterations.$error" error>
            <span v-if="!$v.tournament.stages.$each[index].round_robin_options.iterations.min">
              Insira um número superior a 0
            </span>
            <span v-else-if="!$v.tournament.stages.$each[index].round_robin_options.iterations.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número rondas</span>
          </fw-tip>
        </div>

        <template v-if="stage.round_robin_options.ranking == 'custom'">
          <div class="mb-2">
            <fw-label>Pontos por jogo/set ganho</fw-label>
            <div class="flex flex-col gap-3">
              <NumberInput
                v-model="stage.round_robin_options.pts_for_game_win"
                :placeholder="'Pontos por jogo/set ganho'"
                :max="999"
              ></NumberInput>
            </div>

            <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.pts_for_game_win.$error" error>
              <span v-if="!$v.tournament.stages.$each[index].round_robin_options.pts_for_game_win.max">
                Insira um número inferior a 999
              </span>
              <span v-else>Insira o número de Pontos por jogo/set ganho</span>
            </fw-tip>
          </div>
          <div class="mb-2">
            <fw-label>Pontos por jogo/set empate</fw-label>
            <div class="flex flex-col gap-3">
              <NumberInput
                v-model="stage.round_robin_options.pts_for_game_tie"
                :placeholder="'Pontos por jogo/set empate'"
                :max="999"
              ></NumberInput>
            </div>

            <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.pts_for_game_tie.$error" error>
              <span v-if="!$v.tournament.stages.$each[index].round_robin_options.pts_for_game_tie.max">
                Insira um número inferior a 999
              </span>
              <span v-else>Insira o número de Pontos por jogo/set empate</span>
            </fw-tip>
          </div>
          <div class="mb-2">
            <fw-label>Pontos por partida ganha</fw-label>
            <div class="flex flex-col gap-3">
              <NumberInput
                v-model="stage.round_robin_options.pts_for_match_win"
                :placeholder="'Pontos por partida ganha'"
                :max="999"
              ></NumberInput>
            </div>

            <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.pts_for_match_win.$error" error>
              <span v-if="!$v.tournament.stages.$each[index].round_robin_options.pts_for_match_win.max">
                Insira um número inferior a 999
              </span>
              <span v-else>Insira o número de pontos por partida ganha</span>
            </fw-tip>
          </div>
          <div>
            <fw-label>Pontos por partida por empate</fw-label>
            <div class="flex flex-col gap-3">
              <NumberInput
                v-model="stage.round_robin_options.pts_for_match_tie"
                :placeholder="'Pontos por partida por empate'"
                :max="999"
              ></NumberInput>
            </div>

            <fw-tip v-if="$v.tournament.stages.$each[index].round_robin_options.pts_for_match_tie.$error" error>
              <span v-if="!$v.tournament.stages.$each[index].round_robin_options.pts_for_match_tie.max">
                Insira um número inferior a 999
              </span>
              <span v-else>Insira o número de pontos por partida empate</span>
            </fw-tip>
          </div>
        </template>
      </div>

      <div class="mt-4">
        <fw-label>Número máximo de equipas por torneio</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput v-model="stage.max_teams" :placeholder="'Número de equipas'" :max="20"></NumberInput>
        </div>
        <fw-tip v-if="$v.tournament.stages.$each[index].max_teams.$error" error>
          Insira o número máximo de equipas</fw-tip
        >
      </div>

      <!-- <div v-if="stage.stage == 'intragroups'" class="mt-4">
        <fw-label>Núcleo de estudantes</fw-label>
        <b-select v-model="stage.team_group" placeholder="Selecione um grupo" expanded @blur="$emit('save-team')">
          <option v-for="teamGroup in teamGroups" :key="teamGroup.key" :value="teamGroup">{{
            `${teamGroup.organic_unit.name} - ${teamGroup.name}`
          }}</option>
        </b-select>

        <fw-tip v-if="$v.tournament.stages.$each[index].team_group.$error" error>
          Indique o núcleo de estudantes
        </fw-tip>
      </div> -->
    </div>

    <fw-panel-info debug label="Data (raw)" class="my-4">
      <json-viewer :value="{ tournament, v: $v }"></json-viewer>
    </fw-panel-info>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="loading" :saving="loading" class="w-28" @click.native="createTournament()">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'

import { required, minLength, numeric, minValue, requiredIf } from 'vuelidate/lib/validators'
import {
  GENDERS,
  TOURNAMENT_TYPES,
  TOURNAMENT_STAGES,
  hasOnlyFinalStage,
  ROUND_ROBIN_RANKING_OPTIONS,
} from '@/utils/index.js'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { maxValue } from 'vuelidate/lib/validators'

export default {
  name: 'ModalCreateTournaments',
  components: {
    NumberInput,
    TranslatedInput,
  },
  props: {
    league: {
      type: Object,
      required: true,
    },
    editionKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minSignUpDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      minDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      sports: [],
      stages: TOURNAMENT_STAGES,
      roundRobinRankingOptions: ROUND_ROBIN_RANKING_OPTIONS,
      organicUnits: [],
      teamGroups: [],
      tournament: {
        genders: [],
        title: {
          pt: '',
          en: '',
        },
        description: {
          pt: '',
          en: '',
        },
        sport_key: null,
      },
      loading: true,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    hasOnlyFinalStage() {
      const tournamentSport = this.sports.find(sport => sport.key === this.tournament.sport_key)

      return hasOnlyFinalStage(this.league, tournamentSport)
    },
  },

  validations() {
    return {
      tournament: {
        genders: { required, min: minLength(1) },
        title: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) },
        },
        description: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) },
        },
        sport_key: { required, min: minLength(1) },
        stages: {
          $each: {
            stage: { required, min: minLength(1) },
            type: { required, min: minLength(1) },
            max_teams: { required, min: minValue(1), numeric },

            // team_group: {
            //   required: requiredIf(nestedModal => {
            //     return nestedModal.stage == 'intragroups'
            //   }),
            // },

            swiss_options: {
              required: requiredIf(nestedModal => {
                return nestedModal.type == 'swiss'
              }),
              rounds: {
                numeric,
                min: minValue(1),
                max: maxValue(999),
              },
              pts_for_game_win: { numeric, max: maxValue(999) },
              pts_for_game_tie: { numeric, max: maxValue(999) },
              pts_for_match_win: { numeric, max: maxValue(999) },
              pts_for_match_tie: { numeric, max: maxValue(999) },
            },
            round_robin_options: {
              required: requiredIf(nestedModal => {
                return nestedModal.type == 'round_robin'
              }),
              iterations: {
                numeric,
                min: minValue(1),
                max: maxValue(999),
              },
              ranking: {
                required,
              },
              pts_for_game_win: {
                numeric,
                max: maxValue(999),
                required: requiredIf(nestedModal => {
                  return nestedModal.ranking == 'custom'
                }),
              },
              pts_for_game_tie: {
                numeric,
                max: maxValue(999),
                required: requiredIf(nestedModal => {
                  return nestedModal.ranking == 'custom'
                }),
              },
              pts_for_match_win: {
                numeric,
                max: maxValue(999),
                required: requiredIf(nestedModal => {
                  return nestedModal.ranking == 'custom'
                }),
              },
              pts_for_match_tie: {
                numeric,
                max: maxValue(999),
                required: requiredIf(nestedModal => {
                  return nestedModal.ranking == 'custom'
                }),
              },
            },
          },
        },
      },
    }
  },

  mounted() {
    this.getData()
    if (this.hasOnlyFinalStage) {
      this.tournament.stages = [
        {
          stage: 'final',
          type: 'single_elimination',
          max_teams: 2,
          round_robin_options: {
            ranking: 'points_scored',
            iterations: 1,
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0,
          },
          swiss_options: {
            rounds: 1,
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0,
          },
        },
      ]
    } else {
      this.tournament.stages = []
      for (const key in TOURNAMENT_STAGES) {
        this.tournament.stages.push({
          stage: key,
          type: 'single_elimination',
          max_teams: 2,
          round_robin_options: {
            ranking: 'points_scored',
            iterations: 1,
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0,
          },
          swiss_options: {
            rounds: 1,
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0,
          },
        })
      }
    }
  },

  methods: {
    async getData() {
      this.loading = true
      if (this.league?.type == 'academic') {
        Promise.all([this.getSports(), this.getTeamGroups()])
      } else {
        this.getSports()
      }
      this.loading = false
    },

    async getSports() {
      try {
        const response = await this.api.getSports()
        console.log('getData :>> ', response)
        this.sports = response.sports
      } catch (error) {
        console.error('getData Error: ', error)
      }
    },

    async getOrganicUnits() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getOrganicUnits()
        console.log('getOrganicUnits :>> ', response)
        this.organicUnits = response.data
      })
    },

    async getTeamGroups() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true,
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = response.groups.sort((a, b) =>
          `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`)
        )
      })
    },

    createTournament() {
      this.$v.tournament.$touch()

      if (this.$v.tournament.$invalid) return

      let tournamentStages = JSON.parse(JSON.stringify(this.tournament.stages))

      for (const stage of tournamentStages) {
        stage.organic_unit = stage.team_group?.organic_unit?.name?.toLowerCase()
        stage.team_group = stage.team_group?.name
      }

      this.$emit('create-tournament', { ...this.tournament, stages: tournamentStages })
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "title": {
      "label": "Título",
      "pt": "Título em português",
      "en": "Título em inglês",
      "required": "Insira um título em português e inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em português",
      "en": "Descrição em inglês",
      "required": "Insira uma descriçao em português e inglês."
    }
  },
  "en": {
    "title": {
      "label": "Title",
      "pt": "Title in Portuguese",
      "en": "Title in English",
      "required": "Enter a title in Portuguese and English."
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Insira uma descriçao in Portuguese and English."
    }
  }
}
</i18n>

<style>
.b-checkbox.button {
  border-radius: inherit !important;
}
</style>
