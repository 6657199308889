<template>
  <div v-if="edition">
    <fw-panel :title="$t('editionSettings')" featured sticky :loading="savingData" after-loading-checked>
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button :disabled="savingData" :loading="savingData" type="primary" @click.native="updateEdition()">{{
              $t('save')
            }}</fw-button>

            <fw-button
              v-if="!edition.group_league_active"
              type="primary"
              :disabled="!canGroupLeagues || savingData"
              @click.native="activateGroupLeague()"
              >{{ $t('activateGroupLeague') }}</fw-button
            >
          </div>
        </div>
      </template>
    </fw-panel>

    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="$t('metadata')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div>
          <fw-label>{{ $t('name.label') }}</fw-label>
          <TextInput
            v-if="canEdit"
            v-model="$v.edition.name.$model"
            :disabled="!canEdit"
            :minlength="2"
            :maxlength="250"
            :help="$t('charsLimit', { limit: '250' })"
            :class="{
              error: $v.edition.name.$error,
            }"
          >
          </TextInput>
          <div v-else>
            <div v-if="edition.name">
              {{ edition.name }}
            </div>
            <fw-panel-info v-else clean>{{ edition.name || $t('notDefined') }}</fw-panel-info>
          </div>

          <fw-tip v-if="$v.edition.name.$error" error>
            <span v-if="!$v.edition.name.required">{{ $t('name.required') }}</span>
            <span v-else>{{ $t('name.invalid') }}</span>
          </fw-tip>
        </div>

        <div>
          <fw-tag v-if="edition.group_league_active" type="light-primary">{{ $t('activeGroupLeague') }}</fw-tag>
        </div>
      </fw-panel>
      <fw-panel :title="'Calendarização'" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div class="flex flex-row gap-5">
          <div class="sm:w-1/2">
            <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('startDate.label') }}</span>
            <div v-if="!canEdit" class="font-medium">
              {{ calendar.start_at | formatDate }}
            </div>
            <b-datepicker
              v-else
              v-model="calendar.start_at"
              :placeholder="$t('date.placeholder')"
              icon="calendar-alt"
              locale="pt-PT"
              :min-date="minDate"
              trap-focus
              :class="{
                error: $v.calendar.start_at.$error,
              }"
              :disabled="!canEdit && canEdit"
              @icon-right-click="calendar.start_at = null"
            >
            </b-datepicker>
            <fw-tip v-if="$v.calendar.start_at.$error" error>
              <span v-if="!$v.calendar.start_at.min">{{ $t('startDate.min') }}</span>
              <span v-else>{{ $t('startDate.required') }}</span>
            </fw-tip>
          </div>

          <div class="sm:w-1/2">
            <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('endDate.label') }}</span>
            <div v-if="!canEdit" class="font-medium">{{ calendar.end_at | formatDate }}</div>
            <b-datepicker
              v-else
              v-model="calendar.end_at"
              :class="{
                error: $v.calendar.end_at.$error,
              }"
              :placeholder="$t('date.placeholder')"
              icon="calendar-alt"
              trap-focus
              :min-date="calendar.start_at ?? minDate"
              locale="pt-PT"
              position="is-bottom-left"
              :disabled="!canEdit && canEdit"
              @icon-right-click="calendar.end_at = null"
            >
            </b-datepicker>

            <fw-tip v-if="$v.calendar.end_at.$error" error>
              <span v-if="!$v.calendar.end_at.min">{{ $t('endDate.min') }}</span>
              <span v-else>{{ $t('endDate.required') }}</span>
            </fw-tip>
          </div>
        </div>
      </fw-panel>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import { required, maxLength, minLength, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    TextInput,
    LoadingPlaceholder,
  },

  props: {
    edition: {
      type: Object,
      default: () => {
        return {}
      },
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      calendar: {
        start_at: null,
        end_at: null,
      },
      minDate: Dates.now()
        .startOf('day')
        .subtract(1, 'day')
        .toDate(),
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },

    canGroupLeagues() {
      return !!this.edition.validations?.can_group_leagues
    },
  },

  mounted() {
    this.$v.$reset()
    this.parseEditionDates()
  },

  validations() {
    return {
      edition: {
        name: { required, min: minLength(2), max: maxLength(250) },
      },
      calendar: {
        start_at: { required, min: minValue(this.minDate) },
        end_at: { required, min: minValue(this.calendar.start_at || this.minDate) },
      },
    }
  },

  methods: {
    updateEdition() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = { ...this.edition }
        //add dates
        payload.start_at = this.calendar.start_at.toISOString().slice(0, 10)
        payload.end_at = this.calendar.end_at.toISOString().slice(0, 10)
        this.$emit('update-edition', payload)
      }
    },
    parseEditionDates() {
      let editionAsJson = JSON.parse(JSON.stringify(this.edition))
      this.calendar.start_at = new Date(editionAsJson.start_at.date)
      this.calendar.end_at = new Date(editionAsJson.end_at.date)
    },

    activateGroupLeague() {
      this.$buefy.dialog.confirm({
        confirmText: 'Juntar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Ativar liga "Três Ligas"',
        message: `<div class="has-margin-bottom-small">Ao ativar a liga "Três Ligas", iremos juntar os torneios e equipas existentes na liga Minerva, Alumni e 2I's.
          A partir do momento em que a nova liga seja ativada, o processo é irreversível e deixará de ser possível gerir estas ligar de forma isolada na atual edição.</div>`,
        onConfirm: () => {
          this.$buefy.dialog.prompt({
            confirmText: 'Juntar',
            type: 'is-danger',
            cancelText: 'Cancelar',
            message: `Para confirmar que deseja deseja ativar liga "Três Ligas", por favor,
                escreva "confirmo" na caixa seguinte e carregue no botão "Juntar" novamente.`,
            closeOnConfirm: false,
            inputAttrs: {
              placeholder: 'Escreva "confirmo"',
              maxlength: 8,
            },
            trapFocus: true,
            onConfirm: (value, { close }) => {
              if (value.toLowerCase() == 'confirmo') {
                this.$emit('activate-group-league')
                close()
              }
            },
          })
        },
      })
    },
  },
}
</script>

<i18n lang="json">
{
  "pt": {
    "editionSettings": "Definições da edição",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "metadata": "Metadados",
    "editionCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "name": {
      "label": "Nome da edição",
      "invalid": "Insira um nome da edição válido",
      "required": "Insira o nome da edição"
    },
    "description": {
      "label": "Descrição",
      "placeholder": {
        "pt": "Descrição em Português",
        "en": "Descrição em Inglês"
      },
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido",
    "activateGroupLeague": "Ativar liga Três Ligas",
    "activeGroupLeague": "Três Ligas ativas"
  },
  "en": {
    "editionSettings": "Edition metadata and settings",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "name": {
      "label": "Nome",
      "invalid": "Enter a valid edition name",
      "required": "Enter the edition name"
    },
    "description": {
      "label": "Description",
      "placeholder": {
        "pt": "Description in Portuguese",
        "en": "Description in English"
      },
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "notDefined": "Not defined",
    "activateGroupLeague": "Ativar liga Três Ligas",
    "activeGroupLeague": "Três Ligas ativas"
  }
}
</i18n>
