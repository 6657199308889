<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-3"
    @click="$emit('open')"
  >
    <div class="flex-shrink-0 font-medium flex items-center gap-1">
      <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
    </div>

    <div class="flex-1 font-medium capitalize flex min-w-0 flex-col">
      <div v-if="tournament.title" class="truncate">
        {{ tournament.title[language] }}
      </div>
      <span v-else class="text-sm text-gray-500">{{ $t('notDefined') }}</span>

      <span v-if="sportName" class="text-xs font-semibold text-gray-500">{{ sportName }}</span>
    </div>

    <div class="w-28 flex-shrink-0">
      <fw-tag :type="'medium'" size="xs">
        {{ $t(`gender.${tournament.gender}`) }}
      </fw-tag>
    </div>

    <div class="w-40 flex-shrink-0">
      <fw-tag v-if="tournament.archived_at" :type="'xlight'" size="xs">
        {{ $t(`archived`) }}
      </fw-tag>
      <fw-tag
        v-else
        :type="tournament.is_visible ? (tournament.is_signup_open ? 'primary' : 'light-primary') : 'light-orange'"
        size="xs"
      >
        {{ tournament.is_visible ? (tournament.is_signup_open ? $t(`signupOpen`) : $t(`published`)) : $t(`draft`) }}
      </fw-tag>
    </div>
  </button>
</template>

<script>
export default {
  name: 'RecordTournament',
  props: {
    tournament: {
      type: Object,
      default: () => {},
    },
    isAcademic: {
      type: Boolean,
      default: false,
    },
    sport: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    sportName() {
      return `${this.sport?.modality?.title?.[this.language] ?? '-'} ${this.sport?.category?.[this.language] ?? ''}`
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "notDefined": "Não definido",
    "draft": "Rascunho",
    "published": "Publicado",
    "signupOpen": "Inscrições abertas",
    "signupClosed": "Inscrições fechadas",
    "gender": {
      "U": "Misto",
      "M": "Masculino",
      "F": "Feminino"
    },
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    },
    "archived": "Arquivado"
  },
  "en": {
    "createdAt": "Created at",
    "notDefined": "Não definido",
    "draft": "Rascunho",
    "published": "Publicado",
    "signupOpen": "Signup open",
    "signupClosed": "Signup closed",
    "gender": {
      "U": "Mixed",
      "M": "Male",
      "F": "Female"
    },
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    },
    "archived": "Archived"
  }
}
</i18n>
