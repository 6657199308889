<template>
  <div>
    <LoadingPlaceholder v-if="loading" />
    <fw-panel v-else :title="$t('messages')" featured boxed="xs" custom-class="bg-white p-0">
      <template v-if="canEdit" #toolbar>
        <fw-button type="link" @click.native="newMessage">
          {{ $t('add') }}
        </fw-button>
      </template>

      <fw-panel-info v-if="!loading && !messages.length" type="basic" class="text-center text-gray-500">
        {{ $t('noMessages') }}
      </fw-panel-info>
      <div v-else>
        <CardManageBulkMessage
          v-for="message in messages"
          :key="message.key"
          ref="bulkMessagesList"
          :message="message"
          :user="users[message.user_key]"
          @open="editMessage(message)"
        />
      </div>

      <BlockPagination
        v-if="pagination.total_pages > 1 && !loading"
        :per-page="limit"
        :total="pagination.total_items"
        :total-pages="pagination.total_pages"
        :current.sync="pagination.current_page"
        @page-changed="pageChanged"
      />
    </fw-panel>

    <fw-modal :active.sync="isModalActive" boxed="sm" size="3xl" width="42rem" confirm-close @close="closeModal">
      <ModalGlobalMessage
        v-if="isModalActive"
        :message="selectedMessage"
        :sports="sports"
        :team-groups="teamGroups"
        :edition-key="edition.key"
        :league-key="league.key"
        :can-edit="!selectedMessage.key"
        :is-academic-league="league.type == 'academic'"
        @close="closeModal"
        @send="sendMessage"
      ></ModalGlobalMessage>
    </fw-modal>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ edition, league, messages }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import CardManageBulkMessage from '@/components/cards/manage/CardManageBulkMessage'
import ModalGlobalMessage from '@/components/modals/ModalGlobalMessage'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    CardManageBulkMessage,
    LoadingPlaceholder,
    ModalGlobalMessage,
    BlockPagination,
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },
    league: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      messages: [],
      selectedMessage: null,
      loading: true,
      canEdit: true,
      isModalActive: false,
      pagination: {},
      users: {},
      limit: 20,
      sports: [],
      teamGroups: [],
      loadingSports: false,
      loadingTeamGroups: false,
      teamsCount: 0,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  mounted() {
    this.getMessages()
    this.getSports()
    this.getTeamGroups()
    console.log('this.edition :>> ', this.edition)
    console.log('this.league :>> ', this.league)
  },

  methods: {
    newMessage() {
      this.selectedMessage = {
        description: '',
        status: 'draft',
      }
      this.isModalActive = true
    },

    editMessage(message) {
      message['user'] = this.users[message.user_key]
      this.selectedMessage = message

      console.log('editMessage :>> ', message)
      this.isModalActive = true
    },

    closeModal() {
      this.isModalActive = false
      this.selectedMessage = null
    },

    getMessages() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getGlobalMessages(this.edition.key, this.league.key)
          console.log('getGlobalMessages :>> ', response)
          this.messages = response.global_messages
          this.pagination = response.pagination
          this.users = response.users
        },
        () => {
          this.loading = false
        }
      )
    },

    // async confirmSendMessage(messageData) {
    //   await this.checkMessage(messageData)

    //   console.log('teamsCount :>> ', this.teamsCount)

    //   if (this.teamsCount > 0) {
    //     this.$buefy.dialog.confirm({
    //       confirmText: 'Enviar mensagem',
    //       cancelText: 'Cancelar',
    //       title: `Enviar mensagem global`,
    //       message: `Esta mensagem vai ser enviada para ${this.teamsCount} equipas. Tem a certeza que deseja enviar?`,
    //       onConfirm: () => {
    //         this.sendMessage(messageData)
    //         this.closeModal()
    //       },
    //     })
    //   } else {
    //     this.$buefy.dialog.alert({
    //       title: 'Enviar mensagem global',
    //       message: 'Não há equipas para quem enviar a mensagem.',
    //       type: 'is-danger',
    //       confirmText: 'Ok',
    //     })
    //   }
    // },

    checkMessage(messageData) {
      console.log('messageData :>> ', messageData)
      utils.tryAndCatch(this, async () => {
        const response = await this.api.sendGlobalMessages(this.edition.key, this.league.key, {
          ...messageData,
          confirm: false,
        })
        console.log('checkMessage :>> ', response)
        this.teamsCount = response.count
      })
    },

    sendMessage(messageData) {
      console.log('messageData :>> ', messageData)
      utils.tryAndCatch(this, async () => {
        const response = await this.api.sendGlobalMessages(this.edition.key, this.league.key, {
          ...messageData,
          confirm: true,
        })
        console.log('sendMessage :>> ', response)
        if (this.$refs.bulkMessagesList) this.$refs.bulkMessagesList.refresh()
      })
    },

    async getSports(query) {
      this.loadingSports = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getSports({ query, limit: 50 })
          console.log('getSports :>> ', response)
          this.sports = response.sports
        },
        () => {
          this.loadingSports = false
        }
      )
    },

    async getTeamGroups(query) {
      this.loadingTeamGroups = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTeamGroups({
            query,
            with_org_units: true,
            limit: 50,
          })
          console.log('getTeamGroups :>> ', response)
          this.teamGroups = response.groups.sort((a, b) =>
            `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`)
          )
        },
        () => {
          this.loadingTeamGroups = false
        }
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "messages": "Mensagens",
    "noMessages": "Não existem mensagens para apresentar.",
    "add": "Adicionar"
  },
  "en": {
    "messages": "Mensagens",
    "noMessages": "Não existem mensagens para apresentar.",
    "add": "Adicionar"
  }
}
</i18n>
